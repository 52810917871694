<template>
  <div
    style="padding: 50px 0 0; width: 100%; height: 95%; overflow-y: scroll"
    class="fadeIn"
  >
    <van-nav-bar
      :title="$route.query.pjName"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="9999"
    />
    查询方式：<i
      @click="dateVisible = true"
      class="el-icon-date"
      style="font-size: 20px; color: blue; margin-bottom: 20px"
    ></i>
    <div style="width: 95%; height: 400px" id="barthree"></div>
    <div style="width: 95%; height: 300px" id="piethree"></div>
    <div style="padding-left: 50%; transform: translate(-25%, 0)">
      <h4>总用电量</h4>
      <h2 style="padding-left: 50px; margin-top: 20px">{{ amount }}Kw.h</h2>
    </div>
    <el-dialog
      title="查询条件"
      width="90%
       "
      style="text-align: left"
      :visible.sync="dateVisible"
    >
      <van-notice-bar
        v-if="dateVisible"
        text="周查询和月查询为本年所有周和所有月,无需上传日期！"
      />
      选择方式：
      <van-radio-group v-model="radio" direction="horizontal">
        <van-radio :name="0" icon-size="14px">时查询</van-radio>
        <van-radio :name="1" icon-size="14px">日查询</van-radio>
        <van-radio :name="2" icon-size="14px">班查询</van-radio>
        <van-radio :name="3" icon-size="14px">周查询</van-radio>
        <van-radio :name="4" icon-size="14px">月查询</van-radio>
      </van-radio-group>
      <el-row v-if="radio == 0">
        <div style="margin: 10px 0">
          选择日期：
          <el-date-picker
            v-model="hourDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </el-row>
      <el-row v-if="radio == 1 || radio == 2">
        <div style="margin: 10px 0">
          开始日期：
          <el-date-picker
            v-model="dateValue.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="margin: 10px 0">
          结束日期：
          <el-date-picker
            v-model="dateValue.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </el-row>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end"
      >
        <el-button
          type="primary"
          @click="getUseRate(), (dateVisible = false)"
          size="mini"
          >确 定</el-button
        >
        <el-button @click="dateVisible = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      max: [],
      min: [],
      amount: 0,
      dateVisible: false,
      radio: 1,
      barData: [],
      hourDate: "",
      type: "",
      value: "",
      weekDate: [],
      shiftName: [],
      dateValue: { startDate: "", endDate: "" },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() <
              new Date().setFullYear(new Date().getFullYear() - 1)
          );
        },
      },
    };
  },
  methods: {
    setBar(data, Ydata, ref) {
      const max1 = that.maxValue(that.max);
      const max2 = that.maxValue(that.min);
      const min1 = that.minValue(that.max);
      const min2 = that.minValue(that.min);
      let option = {
        title: {
          text: "运行时长明细",
          subtext: "Duration detail",
          textStyle: {
            fontSize: 12,
          },
          subtextStyle: {
            fontSize: 10,
          },
        },
        tooltip: {
          position: function (point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置

            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }

            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            if (params.length % 5 == 0) {
              let formatterStr = "";
              if (params.length > 5) {
                formatterStr =
                  params[0].axisValue + "<br/>" + that.shiftName[0] + "<br/>";
              } else {
                if (that.radio == 3) {
                  let index = params[0].dataIndex;
                  formatterStr =
                    params[0].axisValue +
                    "<br/>" +
                    '<span style="color:#409eff">' +
                    "开始时间" +
                    "  " +
                    that.weekDate[index]["start"] +
                    "<br/>" +
                    "结束时间" +
                    " " +
                    that.weekDate[index]["end"] +
                    "<br/>" +
                    "</span>";
                } else {
                  formatterStr = params[0].axisValue + "<br/>";
                }
              }
              let arr = [];
              let all = "";
              let allt = "";
              let allT = "";
              let percentage = [];
              if (params.length > 1) {
                all = Number(
                  params[0].data * 3600 +
                    params[1].data * 3600 +
                    params[2].data * 3600 +
                    params[3].data * 3600
                );
              }
              if (params.length > 5) {
                allt = Number(
                  params[5].data * 3600 +
                    params[6].data * 3600 +
                    params[7].data * 3600 +
                    params[8].data * 3600
                );
              }
              if (params.length > 10) {
                allT = Number(
                  params[10].data * 3600 +
                    params[11].data * 3600 +
                    params[12].data * 3600 +
                    params[13].data * 3600
                );
              }
              params.forEach((item, index) => {
                let data = item.data * 3600;
                let str = "";
                if (index == 4 || index == 9 || index == 14) {
                  str = item.data;
                } else {
                  if (data / 3600 >= 1) {
                    str = str + (data - (data % 3600)) / 3600 + "时";
                  }
                  if ((data % 3600) / 60 >= 1) {
                    str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                  }
                  str = str + Math.floor(data % 60) + "秒";
                }
                arr.push(str);
                if (index < 4) {
                  if (all == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / all) * 100).toFixed(2) * 1 || 0);
                  }
                } else if (index > 4 && index < 9) {
                  if (allt == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / allt) * 100).toFixed(2) * 1 || 0);
                  }
                } else if (index > 9 && index < 14) {
                  if (allT == 0) {
                    percentage.push(0);
                  } else {
                    percentage.push(((data / allT) * 100).toFixed(2) * 1 || 0);
                  }
                }
                let dataindex = 0;
                if (index < 5) {
                  dataindex = index;
                } else if (index > 4 && index < 10) {
                  dataindex = index - 1;
                } else if (index < 9) {
                  dataindex = index - 2;
                }
                let newpercentage = "";
                if (index == 4 || index == 9 || index == 14) {
                  newpercentage = "Kw.h";
                } else {
                  newpercentage = "(" + percentage[dataindex] + "%" + ")";
                }
                if (index == 5) {
                  formatterStr +=
                    that.shiftName[1] +
                    "<br/>" +
                    item.marker +
                    item.seriesName +
                    '<span style="color:' +
                    item.color +
                    ';display:inline-block;margin:2px 0px 1px 30px">' +
                    arr[index] +
                    '<span style=" font-weight: 700;">' +
                    newpercentage +
                    "</span>" +
                    "</span>" +
                    "<br/>";
                } else if (index == 10) {
                  formatterStr +=
                    that.shiftName[2] +
                    "<br/>" +
                    item.marker +
                    item.seriesName +
                    '<span style="color:' +
                    item.color +
                    ';display:inline-block;margin:2px 0px 1px 30px">' +
                    arr[index] +
                    '<span style=" font-weight: 700;">' +
                    newpercentage +
                    "</span>" +
                    "</span>" +
                    "<br/>";
                } else {
                  formatterStr +=
                    item.marker +
                    item.seriesName +
                    '<span style="color:' +
                    item.color +
                    ';display:inline-block;margin:2px 0px 1px 30px">' +
                    arr[index] +
                    '<span style=" font-weight: 700;">' +
                    newpercentage +
                    "</span>" +
                    "</span>" +
                    "<br/>";
                }
              });
              return "<div>" + formatterStr + "</div>";
            }
          },
        },
        legend: {
          top: "10% ",
          selectedMode: false,
        },
        grid: {
          top: "25%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0],
            start: 0,
            end: 100,
          },
        ],
        xAxis: [
          {
            type: "category",
            data: Ydata,
            axisLabel: {
              //文字倾斜
              interval: 0,
              rotate: 40,
            },
          },
        ],
        yAxis: [
          {
            position: "left",
            type: "value",
            axisLabel: {
              formatter: "{value}" + "时",
            },
            min: min1,
            max: max1,
            splitNumber: 5,
            interval: (max1 - min1) / 5,
          },
          {
            position: "right",
            name: "用电量",
            type: "value",
            axisLabel: {
              formatter: "{value}" + "Kw.h",
            },
            min: min2,
            max: max2,
            splitNumber: 5,
            interval: (max2 - min2) / 5,
          },
        ],
        series: data,
      };
      var chartDom = document.getElementById(ref);
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
    setPie(Ndata, ref, num) {
      let arr = ["离线", "待机", "异常", "运行"];
      let data = Ndata.filter((item) => arr.includes(item.name));

      let option = {
        title: {
          text: "运行时长总计",
          subtext: "总时长" + (num / 3600).toFixed(2) + "时",
          left: "left",
          textStyle: {
            fontSize: 12,
          },
          subtextStyle: {
            fontSize: 10,
          },
        },
        legend: {
          orient: "vertical",
          left: "75%", //图例距离左的距离
          top: "center",
          // data: legendDate,
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "70%",
            center: ["37%", "50%"],
            data: data,
            label: {
              normal: {
                color: "#e8e8e8",
                position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                // {a}指series.name  {b}指series.data的name
                // {c}指series.data的value  {d}%指这一部分占总数的百分比
                formatter: "{d|{d}%} \n {b|{b}:{c}}" + "时",
                rich: {
                  d: {
                    color: "#fff",
                    fontSize: 16,
                    lineHeight: 24,
                    height: 24,
                  },
                  b: {
                    color: "#f3eae6",
                    fontSize: 14,
                    lineHeight: 20,
                    align: "left",
                  },
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      var chartDom = document.getElementById(ref);
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
    maxValue(arr) {
      const max = Math.max(...arr);
      return Math.ceil((max / 9.5) * 10);
    },
    minValue(arr) {
      // const min = Math.min(...arr);
      return 0;
      // return Math.floor((min / 12) * 10);
    },
    // 稼动率下载
    async dowloadDatas() {
      try {
        if (!this.value) {
          this.$message.warning("请先选择月份");
          return;
        }
        let from = {
          type: 2,
          projectId: this.$route.query.projectId,
          date: this.value,
        };
        const res = await that.$api.triColorLamp.downloadData(from);
        window.open(res.data);
        this.value = "";
        this.dialogVisible = false;
      } catch (e) {
        this.$message.info(e.message || "取消下载");
      }
    },
    getUseRate() {
      that.shiftName = [];
      that.weekDate = [];
      let from = {
        startDate: "",
        endDate: "",
        projectId: that.$route.query.projectId,
        type: that.radio,
      };
      if (that.radio != 1 && that.radio != 2) {
        from.startDate = this.hourDate;
      } else {
        from.startDate = that.dateValue.startDate;
        from.endDate = that.dateValue.endDate;
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$api.energy.geteneryDuration(from).then((res) => {
        loading.close();
        if (res.code == 200) {
          that.setEchartsData(res);
        }
      });
    },
    // 设置柱状图和饼图数据
    setEchartsData(res) {
      if (that.radio == 2) {
        that.ShiftData(res);
        return;
      }
      let wait = []; //待机
      let warning = []; //异常
      let run = []; //运行
      let gray = []; //离线
      let consumption = []; //用电量
      let piewait = 0;
      let piewarning = 0;
      let pierun = 0;
      let piegray = 0;
      let Xdata = [];
      this.max = [];
      this.min = [];
      res.data.forEach((item) => {
        if (that.radio == 3) {
          let obj = {
            start: item.startTime,
            end: item.endTime,
          };
          that.weekDate.push(obj);
        }
        let allTime =
          ((item["0"] + item["1"] + item["2"] + item["3"]) / 3600).toFixed(2) *
          1;
        piegray += item["0"];
        piewarning += item["1"];
        piewait += item["2"];
        pierun += item["3"];
        let newgray = (item["0"] / 3600).toFixed(2) * 1;
        let newred = (item["1"] / 3600).toFixed(2) * 1;
        let newyellow = (item["2"] / 3600).toFixed(2) * 1;
        let newgreen = (item["3"] / 3600).toFixed(2) * 1;
        consumption.push(item.value);
        wait.push(newyellow);
        warning.push(newred);
        run.push(newgreen);
        gray.push(newgray);
        Xdata.push(item["date"]);
        this.max.push(allTime);
        this.min.push(item.value);
      });
      let data = [
        {
          name: "运行",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#32a756",
          },
          data: run,
        },
        {
          name: "待机",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#a7dc5a",
          },
          data: wait,
        },
        {
          name: "异常",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#f3591c",
          },
          data: warning,
        },

        {
          name: "离线",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "gray",
          },
          data: gray,
        },
        {
          name: "用电量",
          type: "line",
          barMaxWidth: 40,
          yAxisIndex: 1,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#4472c4",
          },
          data: consumption,
        },
      ];
      let pieData = [
        {
          value: (piewait / 3600).toFixed(2),
          name: "待机",
          itemStyle: { color: "#a7dc5a" },
        },
        {
          value: (piewarning / 3600).toFixed(2),
          name: "异常",
          itemStyle: { color: "#f3591c" },
        },
        {
          value: (pierun / 3600).toFixed(2),
          name: "运行",
          itemStyle: { color: "#32a756" },
        },
        {
          value: (piegray / 3600).toFixed(2),
          name: "离线",
          itemStyle: { color: "gray" },
        },
      ];
      let num = piewait + piewarning + pierun + piegray;
      let amount = consumption.reduce((a, b) => {
        return a + b * 100;
      }, 0);
      this.amount = amount / 100;
      that.setBar(data, Xdata, "barthree");
      that.setPie(pieData, "piethree", num);
    },
    //班次数据
    ShiftData(res) {
      if (res.data.length < 1) {
        this.$message.warning("暂无数据");
        return;
      }
      let red = [];
      let yellow = [];
      let green = [];
      let gray = [];
      let consumption = [];

      let tgreen = [];
      let tred = [];
      let tyellow = [];
      let tgray = [];
      let tconsumption = [];

      let Tred = [];
      let Tyellow = [];
      let Tgreen = [];
      let Tgray = [];
      let Tconsumption = [];

      let piered = 0;
      let pieyellow = 0;
      let piegreen = 0;
      let piegray = 0;
      let Xdata = [];

      res.data.forEach((it, index) => {
        if (index == 0) {
          it.shifts.forEach((ite) => {
            that.shiftName.push(ite.shiftName);
          });
        }
        Xdata.push(it.date);
        it.shifts.forEach((item, index) => {
          let allTime =
            ((item["0"] + item["1"] + item["2"] + item["3"]) / 3600).toFixed(
              4
            ) * 1;
          this.max.push(allTime);
          this.min.push(item.value);
          piegray += item["0"];
          piered += item["1"];
          pieyellow += item["2"];
          piegreen += item["3"];
          if (index == 0) {
            let newgray = (item["0"] / 3600).toFixed(2) * 1;
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
            gray.push(newgray);
            consumption.push(item.value);
          } else if (index == 1) {
            let newgray = (item["0"] / 3600).toFixed(2) * 1;
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            tred.push(newred);
            tyellow.push(newyellow);
            tgreen.push(newgreen);
            tgray.push(newgray);
            tconsumption.push(item.value);
          } else if (index == 2) {
            let newgray = (item["0"] / 3600).toFixed(2) * 1;
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            Tgray.push(newgray);
            Tred.push(newred);
            Tyellow.push(newyellow);
            Tgreen.push(newgreen);
            Tconsumption.push(item.value);
          }
        });
      });
      let data1 = [
        {
          name: "运行",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#32a756",
          },
          data: green,
        },
        {
          name: "待机",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#a7dc5a",
          },
          data: yellow,
        },
        {
          name: "异常",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#f3591c",
          },
          data: red,
        },

        {
          name: "离线",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: gray,
        },
        {
          name: "用电量",
          type: "line",
          barMaxWidth: 40,
          yAxisIndex: 1,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#4472c4",
          },
          data: consumption,
        },
      ];
      let data2 = [
        {
          name: "运行",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#32a756",
          },
          data: tgreen,
        },
        {
          name: "待机",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#a7dc5a",
          },
          data: tyellow,
        },
        {
          name: "异常",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#f3591c",
          },
          data: tred,
        },

        {
          name: "离线",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: tgray,
        },
        {
          name: "用电量",
          type: "line",
          barMaxWidth: 40,
          yAxisIndex: 1,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#4472c4",
          },
          data: tconsumption,
        },
      ];
      let data3 = [
        {
          name: "运行",
          type: "bar",
          stack: "AA",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#32a756",
          },
          data: Tgreen,
        },
        {
          name: "待机",
          type: "bar",
          stack: "AA",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#a7dc5a",
          },
          data: Tyellow,
        },
        {
          name: "异常",
          type: "bar",
          stack: "AA",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#f3591c",
          },
          data: Tred,
        },

        {
          name: "离线",
          type: "bar",
          stack: "AA",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: Tgray,
        },
        {
          name: "用电量",
          type: "line",
          barMaxWidth: 40,
          yAxisIndex: 1,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "#4472c4",
          },
          data: Tconsumption,
        },
      ];
      let data = [];
      let pieData = [
        {
          value: (pieyellow / 3600).toFixed(2),
          name: "待机",
          itemStyle: { color: "#a7dc5a" },
        },
        {
          value: (piered / 3600).toFixed(2),
          name: "异常",
          itemStyle: { color: "#f3591c" },
        },
        {
          value: (piegreen / 3600).toFixed(2),
          name: "运行",
          itemStyle: { color: "#32a756" },
        },
        {
          value: (piegray / 3600).toFixed(2),
          name: "离线",
          itemStyle: { color: "gray" },
        },
      ];
      if (res.data[0].shifts.length == 2) {
        data = [...data1, ...data2];
      } else {
        data = [...data1, ...data2, ...data3];
      }
      let num = piered + pieyellow + piegreen + piegray;
      let amount = [...consumption, ...tconsumption, ...Tconsumption].reduce(
        (a, b) => {
          return a + b * 100;
        },
        0
      );
      this.amount = amount / 100;
      that.setBar(data, Xdata, "barthree");
      that.setPie(pieData, "piethree", num);
    },
  },
  created() {},
  mounted() {
    that = this;
    let date = new Date();
    let full = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let endDate = full + "-" + month + "-" + Day;
    if (Day > 6) {
      Day -= 6;
      Day = Day < 10 ? "0" + Day : Day;
    } else {
      if (month - 1 < 1) {
        full -= 1;
        month = 12;
      } else {
        month -= 1;
        if (month < 10) {
          month = "0" + month;
        }
      }
      Day = new Date(full, month, 0).getDate() + Day * 1 - 6;
    }

    let startDate = full + "-" + month + "-" + Day;

    that.dateValue = { startDate, endDate };
    this.hourDate = endDate;
    that.getUseRate();
  },
};
</script>
<style scoped>
* {
  /* padding: 0;
  margin: 0; */
  box-sizing: border-box;
  text-align: left;
}
.van-radio {
  margin: 0 3px;
}
/* @import url(); 引入css类 */
</style>
